<script>
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main'
import WorkdayBreakChecksService from '@src/services/WorkdayBreakChecksService'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import workdayBreaksFilterModal from '@/src/router/views/reports/workdayBreaks/workdayBreaksFilterModal.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import moment from 'moment'

const i18nCommon = 'COMMON'
const i18nKey = 'WORKDAYS_BREAKS'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        FilterLabelButton,
        workdayBreaksFilterModal,
        ExportButton,
    },
    mixins: [
        swalFeedback,
        pagination,
        filterVuetable,
        vuetableFormatters,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'datetime_in',
                    direction: 'desc',
                },
            ],
            i18nCommon,
            i18nKey,
            submitLoading: false,
            additionalParameters: {
                with: ['user'],
            },
            modalIsEdit: false,
            modal: {
                id: 0,
                name: '',
                break_time: 0,
                users: [],
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'datetime_in',
                    title: this.getI18n(i18nCommon, 'date'),
                    sortField: 'datetime_in',
                    formatter: (value) => {
                        return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY')
                    },
                },
                {
                    name: 'user.name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    sortField: 'users:user_id|users.name',
                },
                {
                    name: 'datetime_in',
                    title: `${this.getI18n(i18nCommon, 'hours')} ${this.getI18n(i18nCommon, 'start')}`,
                    formatter: this.formatHour,
                },
                {
                    name: 'datetime_out',
                    title: `${this.getI18n(i18nCommon, 'hours')} ${this.getI18n(i18nCommon, 'end')}`,
                    formatter: this.formatHour,
                },
                {
                    name: 'break_time',
                    title: this.getI18n(i18nKey, 'FIELDS.break_time'),
                    formatter: this.formatMinutes,
                },
                {
                    name: 'break_time_scheduled',
                    title: this.getI18n(i18nKey, 'FIELDS.stipulated_break_time'),
                    formatter: this.formatMinutes,
                },
            ],
            filters: {
                datetime_in: null,
                user: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: 'SECURITY',
                        suffix: 'PERMISSIONS.WORKDAYBREAK',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
    },
    methods: {
        fetch(url, params) {
            return WorkdayBreakChecksService.fetchVuetable(url, params)
        },
        i18nWorkdayBreak(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.workday_break',
                modifier: modifier,
            })
        },
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['user'],
                user_id: this.filters.user.map((unit) => unit.id),
                datetime_in: this.formatFilterDate(this.filters.datetime_in),
            })
        },
        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.workdayBreaksFilterModal.showModal(this.filters))
        }
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="workday_break"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nWorkdayBreak(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <workday-breaks-filter-modal
            ref="workdayBreaksFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
