import BaseService from '@src/services/BaseService'

class WorkdayBreakChecksService extends BaseService {
  constructor() {
    super('workday-break-checks')
  }

}

export default new WorkdayBreakChecksService()
